import React from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { getPipEstimateViewModel } from '../../api/index';
import { useGccPipApp } from '../../hooks/useGccPipApp';

export const PipEstimate = () => {
  const { itemId } = useParams();
  const { component } = useGccPipApp(itemId, getPipEstimateViewModel);

  return <>{component}</>;
};

PipEstimate.propTypes = {
};

PipEstimate.displayName = 'PipEstimate';

PipEstimate.defaultProps = {
  name: '',
};
